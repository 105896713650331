<template>
  <div class="bidDashboard">
    <div class="bidDashboard__header">
      <div class="bidDashboard__title">Auctions (Bids)</div>
      <router-link class="bidDashboard-export__button" to="">
        <span
          @click="exportData()"
          class="text">Export</span>
      </router-link>
    </div>
    <div class="buttonsWrapper">
      <div class="navWrapper">
        <button name='0' class="bidDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Today</span>
        </button>
        <button name='1' class="bidDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Previous</span>
        </button>
      </div>
    </div>
    <div class="bidDashboard__content">
      <div class="bidDashboard__content-item js-tabContentItem">
        <BidsTable :dataList="bidList"
                   :rowCount="bidListCount"
                   :pageCount="bidListPageCount"
                   @pageClick="onPageClick"
                   :isHistorical="false"
        />
      </div>
      <div class="bidDashboard__content-item js-tabContentItem">
        <BidsTable :dataList="bidListBefore"
                   :rowCount="bidListCountBefore"
                   :pageCount="bidListPageCountBefore"
                   @pageClick="onPageClickBefore"
                   :isHistorical="true"
        />
      </div>
      <Teleport to="body">
        <NonActualBidsPopup
          :isAdmin=true
        />
      </Teleport>
    </div>
  </div>
</template>

<script>
import './AuctionBidDashboard.styl'
import BidsTable from '@/components/dashboard/BidsTable/BidsTable'
import {Tabs} from '@/js/plugins/tabs';
import Teleport from 'vue2-teleport';

import moment from 'moment';
import NonActualBidsPopup from '@/components/common/modals/NonActualBidsPopup.vue';

export default {
  name: 'AuctionBids',
  props: ['dashboardType'],
  data: () => ({
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
    tabSelected: '0',
  }),
  computed: {
    bidList () {
      return this.$store.state.adminBids.bidList
    },
    bidListCount () {
      return this.$store.state.adminBids.bidListCount
    },
    bidListPageCount () {
      return this.$store.state.adminBids.bidListPageCount
    },
    bidListBefore () {
      return this.$store.state.adminBids.bidListBefore
    },
    bidListCountBefore () {
      return this.$store.state.adminBids.bidListCountBefore
    },
    bidListPageCountBefore () {
      return this.$store.state.adminBids.bidListPageCountBefore
    },
  },
  mounted () {
    this.tabs.addActiveForMouted()
  },
  methods: {
    moment,
    async onPageClick (data) {
      await this.$store.dispatch('getBidList', {...data, historical: false});
    },
    async onPageClickBefore (data) {
      await this.$store.dispatch('getBidList', {...data, historical: true});
    },
    tabActivate (event) {
      this.tabs.addActiveForClick(event.currentTarget)
      this.tabSelected = +event.currentTarget.name
    },
    async exportData () {
      const isHist = !!+this.tabSelected;
      const {data} = await this.$store.dispatch('exportBidList', isHist);

      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', data]);
      const url = window.URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `auction_bid_${isHist ? 'historical_' : ''}report_` + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  },
  components: {
    NonActualBidsPopup,
    BidsTable,
    Teleport,
  },
}</script>
