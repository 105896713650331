<template>
  <div class="bidDashboard__table">
    <Pagination
      :rowCount="rowCount"
      :pageCount="pageCount"
      @pageClick="onPageClick"
      ref="pagination"
    />
    <div class="bidDashboard__table-header">
      <div class="bidDashboard__table-cell">№</div>
      <div class="bidDashboard__table-cell">Flyer</div>
      <div class="bidDashboard__table-cell">Departure Name</div>
      <div class="bidDashboard__table-cell">Arrival Name</div>
      <div class="bidDashboard__table-cell bidDashboard__table-cell--date">Flight Date</div>
      <div class="bidDashboard__table-cell bidDashboard__table-cell--bid">Bid</div>
      <div class="bidDashboard__table-cell bidDashboard__table-cell--date">Created date</div>
      <div class="bidDashboard__table-cell">Willing To Split With Group</div>
    </div>
    <div class="bidDashboard__table-body js-customScroll">
      <vue-scroll :ops="ops">
        <div
          v-for="item of dataList"
          :key="item.id"
          class="bidDashboard__table-row js-acc-item"
          @click="() => getNonActualBidList(item)"
        >
          <div
            class="bidDashboard__table-row-inner js-acc-hover-item"
          >
            <div class="bidDashboard__table-row-header">
              <div class="bidDashboard__table-cell">
                <div class="bidDashboard__table-index">
                  <a class="bidDashboard__table-name" to=""
                  @click="(e) => showPlaneInfo(e, item.aircraftId)"
                >{{item.tailNumber}}</a>
                </div>
              </div>
              <div class="bidDashboard__table-cell">
                <a class="bidDashboard__table-name" to=""
                   @click="(e) => openAdditionalDetails(e, item)">{{item.flyerName}}</a>
              </div>
              <div class="bidDashboard__table-cell">
                {{item.departureName}}
              </div>
              <div class="bidDashboard__table-cell">
                {{item.arrivalName}}
              </div>
              <div class="bidDashboard__table-cell bidDashboard__table-cell--date">{{moment(item.flightDepartureDate).format('M/D/YYYY')}}</div>
              <div class="bidDashboard__table-cell bidDashboard__table-cell--bid">
                <a class="bidDashboard__table-name" to=""
                  @click="(e) => showExlusiveTotalPrice(e, item.exclusiveTotalPrice)"
                >{{item.bidValue.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}}</a>
              </div>
              <div class="bidDashboard__table-cell bidDashboard__table-cell--date">{{moment(item.createdOn).format('M/D/YYYY')}}</div>
              <div class="bidDashboard__table-cell" v-if="!isHistorical">
                <span class="text">{{ item.willingToSplitWithGroup ? 'Yes' : 'No' }}</span>
              </div>
            </div>
            <div class="bidDashboard__table-row-header">
              <div class="bidDashboard__table-cell" v-if="!isHistorical">
                  <span class="bidDashboard__table-cell-button cancel" v-if="!item.accepted" @click="(e) => showConfirmationPopup(e, item)">
                    <span class="text">Cancel bid</span>
                  </span>
                <span class="bidDashboard__table-cell-button cancel" v-if="item.accepted" @click="(e) => showConfirmationPopup(e, item)">
                    <span class="text">Unaccept bid</span>
                  </span>
              </div>
            </div>
          </div>
        </div>
      </vue-scroll>
    </div>
    <ConfirmPopup :text="modalText[confirmType].text" :title="'Are you sure?'" @confirm="confirmModal" :isLoading="isLoading"/>
    <ShortPlaneDetailsPopup :planeInfo="planeInfo" @clearInfo="clearInfo"/>
    <FlyerShortInfoPopup :flyerInfo="flyerInfo" @clearInfo="clearInfo"/>
  </div>
</template>
<script>
import moment from 'moment'
import Pagination from '@/components/common/Pagination/Pagination'
import ShortPlaneDetailsPopup from '@/components/common/modals/ShortPlaneDetailsPopup.vue';
import {aircraftsAPI} from '@/api/aircrafts';
import FlyerShortInfoPopup from '@/components/common/modals/FlyerShortInfoPopup.vue';
import ConfirmPopup from '@/components/common/modals/ConfirmPopop.vue';

export default {
  props: ['pageCount', 'rowCount', 'dataList', 'isHistorical'],
  name: 'BidsTable',
  data: () => ({
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    planeInfo: {},
    flyerInfo: {},
    bidId: null,
    isLoading: false,
    confirmType: 'cancel',
    modalText: {
      cancel: {
        text: 'Are you sure you want to cancel current bid?',
      },
      unaccept: {
        text: 'Are you sure you want to unaccept bid?',
      },
    },
    paginationData: {},
  }),
  computed: {
    nonActualBidList () {
      return this.$store.state.adminBids.nonActualBidList
    },
    isNonActualBidListLoading () {
      return this.$store.state.adminBids.isNonActualBidListLoading
    },
  },
  methods: {
    moment,
    onPageClick (data) {
      this.paginationData = data
      this.$emit('pageClick', data);
    },
    async getNonActualBidList (data) {
      this.$modal.show('nonActualBids')

      await this.$store.dispatch('getNonActualBidList', data.flightId);
    },
    showConfirmationPopup (e, item) {
      e.stopPropagation()
      this.confirmType = item.accepted ? 'unaccept' : 'cancel'

      this.$modal.show('confirmPopup')
      this.bidId = item.id
    },
    confirmModal () {
      if (this.confirmType === 'cancel') {
        this.cancelBid()
      } else {
        this.unacceptBid()
      }
    },
    async cancelBid () {
      this.isLoading = true
      await this.$store.dispatch('cancelBid', this.bidId);

      await this.$store.dispatch('getBidList', {historical: this.isHistorical, ...this.paginationData});
      this.$modal.hide('confirmPopup')
      this.isLoading = false
    },
    async unacceptBid () {
      this.isLoading = true
      await this.$store.dispatch('unacceptBid', this.bidId);

      await this.$store.dispatch('getBidList', {historical: false, ...this.paginationData});
      this.$modal.hide('confirmPopup')
      this.isLoading = false
    },
    async showExlusiveTotalPrice (e, exlusiveTotalPrice) {
      e.stopPropagation()
      const message = {
        title: 'Outright price',
        text: exlusiveTotalPrice.toLocaleString('en-US', {style: 'currency', currency: 'USD'}),
      }
      this.$modal.show('ThanksPopup', {message})
    },
    async showPlaneInfo (e, id) {
      e.stopPropagation()

      const {data} = await aircraftsAPI.getShortPlaneInfo(id)
      this.planeInfo = data.item.value

      this.$modal.show('ShortPlaneDetailsPopup')
    },
    async openAdditionalDetails (e, info) {
      e.stopPropagation()

      const { flyerId, flyerName, flyerEmail, flyerMobile } = info

      this.flyerInfo = {
        flyerId,
        flyerName,
        flyerEmail,
        flyerMobile,
      };

      this.$modal.show('FlyerShortInfoPopup')
    },
    clearInfo () {
      this.planeInfo = {}
      this.flyerInfo = {}
    },
  },
  components: {
    ConfirmPopup,
    FlyerShortInfoPopup,
    ShortPlaneDetailsPopup,
    Pagination,
  },
}
</script>
